import { GqlQueryArgument, GqlQueryPart } from "../services/graphql.module/model";

export function CreateGqlQueryArguments(argsConst: GqlQueryArgument[], values: any[]): GqlQueryArgument[] {
    return argsConst.map((arg, index) => ({
      ...arg,
      variableValue: values[index],
    }));
  }
  
  export function CreateNoParamsQueryPart(queryName: string, returnPropsCsv: string): GqlQueryPart {
    var result: GqlQueryPart = {
      queryConstant: queryName + "{" + returnPropsCsv + "}",
      arguments: null
    };
    return result;
  }